import * as keys from "../keys"

export const en: { [index: string]: string } = {}

en[keys.TEXT_SAVE] = "Save"
en[keys.TEXT_DASHBOARD] = "Dashboard"
en[keys.TEXT_PRODUCT] = "Product"
en[keys.TEXT_PRODUCTS] = "Products"
en[keys.TEXT_PRODUCTS_INVOICED] = "Invoiced products"
en[keys.TEXT_PRODUCTS_REFUNDED] = "Refunded products"
en[keys.TEXT_CONTRACT] = "Contracts"
en[keys.TEXT_FILTERS] = "Filters"
en[keys.TEXT_SEARCH] = "Search"
en[keys.TEXT_LANGUAGE] = "Language"
en[keys.TEXT_LOGOUT] = "Logout"
en[keys.TEXT_LOGIN] = "Login"
en[keys.TEXT_CANCEL] = "Cancel"
en[keys.TEXT_CODE] = "Code"
en[keys.TEXT_PERCENTAGE] = "Percentage"
en[keys.TEXT_ADD_NEW] = "Add new"
en[keys.TEXT_EDIT] = "Edit"
en[keys.TEXT_DELETE] = "Delete"

en[keys.TEXT_FILTER_CONTRACT_PLACEHOLDER] = "Filter by martikkel number, address, name or casenumber"
en[keys.TEXT_FILTER_PRODUCT_PLACEHOLDER] = "Filter by name or description"
en[keys.TEXT_RUN_TEST_BILLING] = "Run test billing"
en[keys.TEXT_RUN_BILLING] = "Run billing"
en[keys.TEXT_DESCRIPTION] = "Description"
en[keys.TEXT_VALID_FROM] = "Valid from"
en[keys.TEXT_VALID_TO] = "Valid to"
en[keys.TEXT_PROPERTY_NUMBER] = "Property number"
en[keys.TEXT_GNR_BNR] = "Matrikkel number"
en[keys.TEXT_SERIALNUMBER] = "Serial number"
en[keys.TEXT_CONTRACTTYPE] = "Type"
en[keys.TEXT_APARTMENT_NUMBER] = "Apartment number"
en[keys.TEXT_POST_ADDRESS] = "Post address"
en[keys.TEXT_POST_CODE] = "Post code"
en[keys.TEXT_ACTIVE_PRODUCTS] = "Active products"
en[keys.TEXT_COUNT_ACTIVE_PRODUCTS] = "# active products"
en[keys.TEXT_OWNER_FULL_NAME] = "Name"
en[keys.TEXT_OWNER_RECIPIENT] = "Recipient"
en[keys.TEXT_COUNT_RESIDENTIAL_NUMBER] = "Residential numbers"
en[keys.TEXT_PRODUCT_DETAILS] = "Product details"
en[keys.TEXT_CREDIT_NOTE] = "Credit note"
en[keys.TEXT_CREATE_CREDIT_NOTE] = "Create credit note"
en[keys.TEXT_CREATE_CREDIT_NOTE_FOR_LINES] = "Create credit note for selected lines"
en[keys.TEXT_INVOICE_LINE_SELECTED] = "invoice lines selected"
en[keys.TEXT_PRICE] = "Price"
en[keys.TEXT_PREVIOUS_VERSIONS] = "Previous versions"
en[keys.TEXT_VERSION] = "Version"
en[keys.TEXT_UPDATED] = "updated"
en[keys.TEXT_INVOICES] = "Invoices"
en[keys.TEXT_DATE] = "Date"
en[keys.TEXT_PRODUCT_NAME] = "Product name"
en[keys.TEXT_CUBIT_BILLING] = "Cubit billing"
en[keys.TEXT_ADDRESS] = "Address"
en[keys.TEXT_INVOICE_RECEIVER] = "Invoice receiver"
en[keys.TEXT_OWNER] = "Owner"
en[keys.TEXT_UTILITY_UNIT] = "Utility unit"
en[keys.TEXT_COUNT_UTILITY_UNITS] = "Utility units"
en[keys.TEXT_UTILITYUNITS_AND_PRODUCTS] = "Utility units and products"
en[keys.TEXT_TERMIN] = "Term"
en[keys.TEXT_CASE_NUMBER] = "Case number"
en[keys.TEXT_SHARED_INVOICE] = "shared with"
en[keys.TEXT_SHARED_REFUND_TXT] = "All lines receive credit note"
en[keys.TEXT_DATE_SENT] = "Date sent"
en[keys.TEXT_LOAD_TEMPLATE] = "Load template"

en[keys.TEXT_STATUS] = "Status"
en[keys.TEXT_STATUS_ACTIVE] = "Active"
en[keys.TEXT_STATUS_DRAFT] = "Draft"
en[keys.TEXT_STATUS_HISTORIC] = "Historic"

en[keys.TEXT_SETTINGS] = "Settings"
en[keys.TEXT_USER] = "User"
en[keys.TEXT_WELCOME_TO] = "Welcome to"
en[keys.TEXT_PASSWORD] = "Password"

en[keys.TEXT_E_MAIL] = "Email"
en[keys.TEXT_NAME] = "Name"
en[keys.TEXT_NUMBER] = "Number"
en[keys.TEXT_TYPE] = "Type"
en[keys.TEXT_UNAUTHORIZED] = "Unauthorized"
en[keys.TEXT_FILTER] = "Filter"

en[keys.TEXT_CHIMNEY] = "Chimney"
en[keys.TEXT_HOUSING_UNIT] = "Housing unit"
en[keys.TEXT_AUDIT] = "Audit"

en[keys.TEXT_REQUIRED] = "Required"
en[keys.TEXT_ALREADY_EXISTS] = "Value already exists"
en[keys.TEXT_NOT_FOUND] = "Value not found"
en[keys.TEXT_INVALID] = "Invalid value"
en[keys.TEXT_TYPE_NUMBER] = "Value must be a number"

en[keys.TEXT_NO_INVOICES] = "No historical invoices"
en[keys.TEXT_TEST_INVOICES] = "Test invoices"
en[keys.TEXT_TOTAL_PRICE] = "Total price"
en[keys.TEXT_PERIOD] = "Period"
en[keys.TEXT_LINE] = "Line"
en[keys.TEXT_QUANTITY] = "Quantity"
en[keys.TEXT_LINE_TOTAL] = "Line total"
en[keys.TEXT_UNIT_PRICE] = "Unit price"
en[keys.TEXT_SUM] = "Sum"
en[keys.TEXT_SUM_ALL_PRODUCTS] = "Sum for all products"
en[keys.TEXT_LAST_INVOICING] = "Last invoicing"
en[keys.TEXT_LAST_TEST_INVOICING] = "Last test invoicing"
en[keys.TEXT_INVOICE_DATE] = "Date of invoicing"
en[keys.TEXT_TEST_DATE] = "Test invoicing date"
en[keys.TEXT_CONVERT_TO_INVOICE] = "Convert to invoice"
en[keys.TEXT_ADD_VAT_CODE] = "Add VAT code"
en[keys.TEXT_VAT_CODE] = "VAT code"
en[keys.TEXT_FRACTION] = "Fraction"
en[keys.TEXT_MVA] = "VAT"
en[keys.TEXT_PRODUCT_NUMBER] = "Product number"
en[keys.TEXT_PRD_NO] = "Product no"

en[keys.TEXT_PRODUCTTYPE_CHIMNEY] = "Chimney"
en[keys.TEXT_PRODUCTTYPE_HOUSINGUNIT] = "Housing Unit"
en[keys.TEXT_PRODUCTTYPE_AUDIT] = "Audit"
en[keys.TEXT_PRODUCTSTATUS_ACTIVE] = "Active"
en[keys.TEXT_PRODUCTSTATUS_DRAFT] = "Draft"
en[keys.TEXT_PRODUCTSTATUS_HISTORIC] = "Historic"
en[keys.TEXT_SYNC_ERP] = "Sync with ERP"

en["TEXT_SEARCH_CATEGORYAddress"] = "Address"
en["TEXT_SEARCH_CATEGORYOwnerName"] = "Name"
en["TEXT_SEARCH_CATEGORYMatrikkelNumber"] = "Matrikkel number"
en["TEXT_SEARCH_CATEGORYPostalCode"] = "Postal code"
en["TEXT_INVOICE_ADDRESS"] = "Invoice address"
en["TEXT_TERM_SHARE"] = "Term share"
en["TEXT_CREATE_CREDIT_NOTE_AND_COPY"] = "Create credit note and copy for new invoice"
en["TEXT_CREATE_NEW_INVOICE"] = "Create new invoice"
en[keys.TEXT_ADD_PRODUCT] = "Add new product"
en["TEXT_HOUSINGUNIT_INVOICE_LINES"] = "Invoice lines related to housing unit"
en["TEXT_TOTAL_SUM"] = "Total sum"
en["TEXT_GENRAL_INVOICES_CONFIG"] = "Invoices"
en[keys.TEXT_GENRAL_REFUNDS_CONFIG] = "Refunds"

en[keys.TEXT_INVOICE_PROCESSING_UPON_IMPORT] =
    "Automatically send new invoices to ERP at the moment of invoice creation"
en["TEXT_INVOICING_ENABLED"] = "Sending to ERP enabled"
en[keys.TEXT_SINGLE_INVOICELINE_REFUND_ENABLED] = "Allow refunding of individual invoice lines"
en[keys.TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS] = "Allow editing products for contracts"
en[keys.TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS] =
    "Always re-create invoices even for closed periods on contract change"

en[keys.TEXT_ERP_RESEND_ENABLED] = "Resend to ERP enabled"
en["TEXT_INVOICE_SYSTEM"] = "Invoice system"
en["TEXT_INVOICE_SYSTEM_CONFIG"] = "Invoice system - ERP"
en["TEXT_TENANT_CONFIGURATION"] = "Tenant configuration"
en[keys.TEXT_INVOICES_AND_CONTRACTS_CONFIG] = "Adaptations"
en["TEXT_INVOICE_INTEGRATION_CONFIG"] = "ERP Integration configuration"
en["TEXT_NONE"] = "None"
en["TEXT_ERP_VISMA"] = "Visma"
en["TEXT_ERP_AGRESSO"] = "Agresso"
en["TEXT_ERP_ISYPA"] = "Isy PA"
en["TEXT_ERP_AZUREFILE_RINGERIKE"] = "Azure file share for Ringerike"
en["TEXT_ERP_AZUREFILE_BERGEN"] = "Agresso - Bergen Brannvesen"
en["TEXT_ERP_AZUREFILE_ISYPA"] = "Azure file share for Isy PA"
en[keys.TEXT_PERIODCONFIG_TERMS] = "{0} terms"
en["TEXT_PERIODCONFIG_NOTERMS"] = "Terms not defined"
en["TEXT_REFUND_CONFIRMATION"] = "Are you sure you would like to credit selected item?"
en[keys.TEXT_TERMS] = "Terms"
en["TEXT_TERMS_QUANTITY"] = "Terms count"
en["TEXT_START_DATE"] = "Start date"
en["TEXT_END_DATE"] = "End date"
en["TEXT_LAST_POST_DATE"] = "Final post date"
en["TEXT_TRANSFER_DATE"] = "Transfer date"
en["TEXT_TERMIN_START"] = "Term start"
en["TEXT_TERMIN_END"] = "Term end"
en["TEXT_AMOUNT"] = "Amount"
en["TEXT_NETAMOUNT"] = "Net Amount"
en["TEXT_VATAMOUNT"] = "VAT Amount"
en["TEXT_DOCUMENTS_NUMBER"] = "Number of documents"
en["TEXT_INVOICES_PRODUCT_NUMBER"] = "Number of products"
en["TEXT_REFUNDS_NUMBER"] = "Number of refunds"
en[keys.TEXT_SEND_TO_ERP] = "Send to ERP"
en["TEXT_REFRESH_PERIOD_STATISTICS"] = "Refresh"
en["TEXT_DOWNLOAD_INVOICE_SUMMARY"] = "Get report"
en["TEXT_MIGRATE_DATA"] = "Run migration"
en["TEXT_MIGRATE_DATA_HEADER"] = "Migrate contracts"
en["TEXT_MIGRATION_IN_PROGRESS"] = "Loading contracts"

en[keys.TEXT_EDIT_CONTRACT_PRODUCTS] = "Edit products"
en[keys.TEXT_ADD_CONTRACT_PRODUCT] = "Add product"
en[keys.TEXT_PRODUCT_LINE_SELECTED] = "product lines selected"
en[keys.TEXT_PRODUCTS_TO_UPDATE] = "products to edit"
en[keys.TEXT_PRODUCT_BILLING_TYPE] = "Type"
en[keys.TEXT_PRODUCT_PERIODIC] = "Periodic"
en[keys.TEXT_PRODUCT_ACTIVITY_BASED] = "Ativity based"
en[keys.TEXT_VALIDITY] = "Validity"
en[keys.TEXT_FROM] = "from"
en[keys.TEXT_TO] = "to"
en[keys.TEXT_PRODUCT_INVOICE_PERIODS] = "Invoiced"
en[keys.TEXT_PRODUCT_EACH_PERIOD] = "Each period"
en[keys.TEXT_PRODUCT_EACH_MAIN_PERIOD] = "Each main period"
en[keys.TEXT_PRODUCT_SELECT_PERIOD] = "Select periods"
en[keys.TEXT_CONTRACT_USE_CUSTOM_TERM] = "Custom term on all periodic products"
en[keys.TEXT_MAIN_PERIOD] = "Main period"
en[keys.TEXT_SELECT_PERIOD_COUNT] = "Select period count"
en[keys.TEXT_CONTRACT_USE_CUSTOM_TERMS_TOOLTIP] = "Overrides which terms periodic products are invoiced"

en[keys.TEXT_VALIDATION_ALREADY_EXISTS] = "Already exists"
en[keys.TEXT_VALIDATION_INVALID_VALUE] = "Invalid value"
en[keys.TEXT_VALIDATION_MUST_BE_NUMBER] = "Must be a number"
en[keys.TEXT_VALIDATION_NOT_FOUND] = "Not found"
en[keys.TEXT_VALIDATION_DATE_FROM_SMALLER] = "Date from must be smaller than date to"
en[keys.TEXT_VALIDATION_DATE_INVALID] = "Invalid date"
en[keys.TEXT_NOT_INVOICED_LINES] = "Uninvoiced lines"

en[keys.TEXT_ERP_KOMTEK_FILE] = "KomTek"
en[keys.TEXT_RUN_CONTRACT_MIGRATION] = "Run migration process to migrate new contracts to the billing system."
en[keys.TEXT_PRODUCT_UPDATED_ICON_TOOLTIP] = "The product has been changed. Invoices must be updated"
en[keys.TEXT_CONTRACT_MAIN_INFO_SERIAL_NUMBER_ERROR] = "Serial number already exists on this matrikkel unit"
en[keys.TEXT_NOT_ANY] = "No any"

en[keys.TEXT_GENERAL_CONTRACT_CONFIG] = "Kontrakter"

en[keys.TEXT_SERVICE_URL] = "Service URL"
en[keys.TEXT_USER_NAME] = "User name"
en[keys.TEXT_USER_PASSWORD] = "Password"
en[keys.TEXT_COMPANY_ID] = "Company ID"
en[keys.TEXT_EXTERNAL_SYSTEM] = "External system"
en[keys.TEXT_CLIENT_NUMBER] = "Client number"
en[keys.TEXT_DEFAULT_CLIENT_NUMBER] = "Default client number"
en[keys.TEXT_ADD_CLIENT_NUMBER_PERIOD] = "Add period"
en[keys.TEXT_DOMAIN] = "Domain"
en[keys.TEXT_APPLICATION_ID] = "Application ID"
en[keys.TEXT_CLIENT_ID] = "Client ID"
en[keys.TEXT_CLIENT_NUMBER_SECTION_NAME] = "Client number"
en[keys.TEXT_PRODUCT_GROUP] = "Product group"

en[keys.TEXT_VISMA_FILE] = "Visma file"
en[keys.TEXT_KOMTEK_CHANGE_FILE] = "KomTek change file"
en[keys.TEXT_DUETT_FILE] = "Duett file"
en[keys.TEXT_USE_BUILDING_NUMBER] = "Use building number as serial number"
en[keys.TEXT_USE_PERIODIC_FEE_TEXT_FORMAT] = "Group by products and period text"

en[keys.TEXT_EMPTY] = "Empty"
en[keys.TEXT_BUILDING_NUMBER] = "Building number"
en[keys.TEXT_SERIAL_NUMBER] = "Serial number"
en[keys.TEXT_CUSTOM_VALUE] = "Custom value"
en[keys.TEXT_CONFIGURATION] = "configuration"

en[keys.TEXT_SELECT_CONTRACT_FILE] = "Select Contract file"
en[keys.TEXT_SELECT_INVOICE_FILE] = "Select Invoice file"
en[keys.TEXT_IMPORT_DATE] = "Import date"
en[keys.TEXT_MUNICIPALITY_NUMBER] = "Municipality"
en[keys.TEXT_IMPORT_KOMTEK_FILES] = "Import"
en[keys.TEXT_KOMTEK_IMPORT] = "Komtek file import"

en[keys.TEXT_GENERATE_DIFF_FILE] = "Generate diff file"

en[keys.TEXT_KOMTEK_IMPORT_TITLE] =
    "By importing baseline from Komtek, data already in Cubit Billing from the selected time period is overwritten. The action cannot be undone."

en[keys.TEXT_IMPORTING] = "Importing..."
en[keys.TEXT_DONE] = "Done."

en[keys.TEXT_INVOICE_TYPE] = "Cost type"
en[keys.TEXT_PERIODIC_COST_TYPE_RUNNING] = "Running cost (has no end date set)"
en[keys.TEXT_PERIODIC_COST_TYPE_NORMAL] = "Normal cost (has end date set)"
en[keys.TEXT_GROUPING_TYPE_BY_PRODUCT] = "Group similar products on the same agreement"
en[keys.TEXT_NO] = "No"
en[keys.TEXT_YES] = "Yes"

en[keys.TEXT_USE_WHOLE_BUILDING_NUMBER] = "Include whole building number"
en[keys.TEXT_USE_SHORT_BUILDING_NUMBER] = "Include only last 4 digits of building number"
en[keys.TEXT_OVERRIDE_DEFAULT_SERIAL_NUMBER_WITH] = "Replace default value 1 with"
en[keys.TEXT_AGRESSO_MIDT] = "Agresso Standard"
en[keys.TEXT_REFERENCE_CODE] = "Reference code DR"
en[keys.TEXT_RESPONSIBLE] = "Responsible"
en[keys.TEXT_SALESMAN] = "Salesman"
en[keys.TEXT_VOUCHER_TYPE] = "Voucher type"
en[keys.TEXT_GROUP_BY_CONTRACT] = "Group by contract"
en[keys.TEXT_GROUP_BY_BUILDING_NUMBER] = "Group by building number"
en[keys.TEXT_UNIT_CODE] = "Unit code"
en[keys.TEXT_OK] = "Ok"
en[keys.TEXT_INVOICES_FAILED_SENDING_TO_ERP] = "invoice(s) failed on sending to ERP system:"
en[keys.TEXT_INVOICES_SENT_SUCCESSFULLY_TO_ERP] = "invoice(s) sent successfully to ERP system!"
en[keys.TEXT_NEW_INVOICES_CREATED_BY_USER] = "New invoices"
en[keys.TEXT_PERIOD_TITLE_CREDITING] = "Crediting"
en[keys.TEXT_PERIOD_TITLE_NEW_INVOICES] = "New invoices"
en[keys.TEXT_TRIPLETEX] = "Tripletex"
en[keys.TEXT_TRIPLETEX_API_URL] = "Api url"
en[keys.TEXT_TRIPLETEX_CONSUMER_TOKEN] = "Consumer token"
en[keys.TEXT_TRIPLETEX_EMPLOYEE_TOKEN] = "Employee token"
en[keys.TEXT_FILTER_NSO_CONTRACT_PLACEHOLDER] = "Filter by organization number, address or name"
en[keys.TEXT_ORGANIZATION_NUMBER] = "Organization number"
en[keys.TEXT_SEARCH_CATEGORYOrganizationNumber] = "Organization number"
en[keys.TEXT_ORGANIZATIONS_COUNT] = "Organization count"
en[keys.TEXT_ORGANIZATIONS] = "Organisasjoner"
en[keys.TEXT_ORGANIZATIONS_AND_PRODUCTS] = "Organizations and products"
