import React, { useEffect, useState } from "react"
import { tr } from "../../utils/translations/translate"
import {
    TEXT_INVOICES,
    TEXT_PRICE,
    TEXT_TERMIN,
    TEXT_CASE_NUMBER,
    TEXT_DATE_SENT,
    TEXT_MVA,
    TEXT_SUM,
    TEXT_QUANTITY,
    TEXT_DESCRIPTION,
    TEXT_PRODUCT,
    TEXT_NETAMOUNT,
    TEXT_PRD_NO,
    TEXT_ADDRESS,
} from "../../utils/translations/keys"
import { makeStyles, Theme, Paper, Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from "@material-ui/core"
import Spinner from "common/spinner"
import { InvoiceLine } from "models/invoice"
import { toShortDate } from "utils/time/timeUtil"
import { AppState } from "redux/app/app-store"
import { useDispatch, useSelector } from "react-redux"
import { getFractionString } from "utils/invoice/invoice-utils"
import classNames from "classnames"
import { formatDecimal } from "./contract-helper"
import { InvoiceType } from "common/enums/invoiceType"
import { useStyles } from "./contract-invoices-styles"
import { actionUpdateSelectedContractInvoices } from "../../redux/contract/contract-actions"
import { UpdateInvoiceIsSelectedType } from "../../redux/contract/contract-reducer"

type ContractInvoices = {
    title: string
    refundMode?: boolean
    invoiceLines: InvoiceLine[]
    isLoadingInvoices: boolean
}

const ContractInvoices: React.FC<ContractInvoices> = props => {
    const { title, refundMode, invoiceLines, isLoadingInvoices } = props

    const dispatch = useDispatch()
    const styles = useStyles()
    const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig)

    const calculatePeriod = (periodYear: number, period: number) => {
        return periodYear * 100 + period
    }

    const selectLine = (line: InvoiceLine) => {
        const periodNumber = !tenantConfig?.isRefundSingleInvoiceLineEnabled
            ? calculatePeriod(line.periodYear, line.period)
            : 0

        const selected: UpdateInvoiceIsSelectedType[] = tenantConfig?.isRefundSingleInvoiceLineEnabled
            ? [{ id: line.id, isSelected: !line.isSelected, lineNo: line.lineNo }]
            : invoiceLines
                  .filter(x => calculatePeriod(x.periodYear, x.period) === periodNumber && x.type !== InvoiceType.Refund && x.markedForRefund === false)
                  .map(x => ({ id: x.id, isSelected: !x.isSelected, lineNo: line.lineNo }))

        dispatch(actionUpdateSelectedContractInvoices(selected, tenantConfig?.isRefundSingleInvoiceLineEnabled ?? false))
    }

    let lastPeriodValue: number = 0
    const invoicesSum = invoiceLines.reduce((prev, current) => current.lineSum + prev, 0)
    const invoicesNetSum = invoiceLines.reduce((prev, current) => current.lineTotal + prev, 0)

    return (
        <Paper className={refundMode ? `${styles.paper} ${styles.accentBG}` : styles.paper}>
            {isLoadingInvoices && <Spinner />}
            {!isLoadingInvoices && (
                <>
                    <h3 className={styles.h3}>{title}</h3>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                {refundMode && <TableCell className={styles.checkBoxCell}></TableCell>}
                                <TableCell className={styles.tableHead} style={{ width: "7%" }}>
                                    {tr(TEXT_TERMIN)}
                                </TableCell>
                                <TableCell className={styles.tableHead} style={{ width: "9%" }}>
                                    {tr(TEXT_DATE_SENT)}
                                </TableCell>
                                <TableCell className={styles.tableHead} style={{ width: "7%" }}>
                                    {tr(TEXT_CASE_NUMBER)}
                                </TableCell>
                                <TableCell
                                    className={classNames(styles.tableHead, styles.alignRight)}
                                    style={{ width: "7%" }}
                                >
                                    {tr("TEXT_INVOICE_RECEIVER")}
                                </TableCell>
                                <TableCell
                                    className={classNames(styles.tableHead, styles.alignRight)}
                                    style={{ width: "7%" }}
                                >
                                    {tr(TEXT_PRD_NO)}
                                </TableCell>
                                <TableCell className={styles.tableHead} style={{ width: "12%" }}>
                                    {tr(TEXT_PRODUCT)}
                                </TableCell>
                                <TableCell className={styles.tableHead} style={{ width: "14%" }}>
                                    {tr(TEXT_DESCRIPTION)}
                                </TableCell>
                                <TableCell
                                    className={classNames(styles.tableHead, styles.alignRight)}
                                    style={{ width: "7%" }}
                                >
                                    {tr(TEXT_ADDRESS)}
                                </TableCell>
                                <TableCell
                                    className={classNames(styles.tableHead, styles.alignRight)}
                                    style={{ width: "8%" }}
                                >
                                    {tr(TEXT_QUANTITY)}
                                </TableCell>
                                <TableCell
                                    className={classNames(styles.tableHead, styles.alignRight)}
                                    style={{ width: "9%" }}
                                >
                                    {tr(TEXT_PRICE)}
                                </TableCell>
                                <TableCell
                                    className={classNames(styles.tableHead, styles.alignRight)}
                                    style={{ width: "9%" }}
                                >
                                    {tr(TEXT_NETAMOUNT)}
                                </TableCell>
                                <TableCell
                                    className={classNames(styles.tableHead, styles.alignRight)}
                                    style={{ width: "6%" }}
                                >
                                    {tr(TEXT_MVA)}
                                </TableCell>
                                <TableCell
                                    className={classNames(styles.tableHead, styles.alignRight)}
                                    style={{ width: "10%" }}
                                >
                                    {tr(TEXT_SUM)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoiceLines.map(line => {
                                const periodNumber = calculatePeriod(line.periodYear, line.period)
                                const isNewGroup = periodNumber !== lastPeriodValue
                                lastPeriodValue = periodNumber

                                const isRefundable = line.type !== InvoiceType.Refund && !line.markedForRefund
                                
                                return (
                                    <TableRow
                                        key={line.invoiceId + line.lineNo}
                                        className={`${line.type === InvoiceType.Refund ? styles.refund : ""} ${
                                            line.markedForRefund ? styles.markedForRefund : ""
                                        } ${isNewGroup ? styles.invoiceGroupEnd : ""}`}
                                    >
                                        {refundMode && (
                                            <TableCell className={`${styles.checkBoxCell} ${styles.invoiceRow}`}>
                                                {isRefundable && (
                                                    <Checkbox
                                                        className={styles.checkbox}
                                                        checked={line.isSelected}
                                                        onClick={() => {
                                                            selectLine(line)
                                                        }}
                                                    />
                                                )}
                                            </TableCell>
                                        )}
                                        <TableCell className={styles.invoiceRow}>
                                            {line.period} {line.periodYear}
                                        </TableCell>
                                        <TableCell className={styles.invoiceRow}>
                                            {toShortDate(line.transferDate) || "-"}
                                        </TableCell>
                                        <TableCell className={styles.invoiceRow}>{line.caseNumber}</TableCell>
                                        <TableCell className={styles.invoiceRow}>{line.invoiceRecipient}</TableCell>
                                        <TableCell className={classNames(styles.invoiceRow, styles.alignRight)}>
                                            {line.productNumber ?? "-"}
                                        </TableCell>
                                        <TableCell className={styles.invoiceRow}>
                                            {line.buyerProductDesc || "-"}
                                        </TableCell>
                                        <TableCell className={styles.invoiceRow}>{line?.description || "-"}</TableCell>
                                        <TableCell className={classNames(styles.invoiceRow, styles.alignRight)}>
                                            {line.address}
                                        </TableCell>
                                        <TableCell className={classNames(styles.invoiceRow, styles.alignRight)}>
                                            {getFractionString(line.numerator, line.denominator)}
                                        </TableCell>
                                        <TableCell className={classNames(styles.invoiceRow, styles.alignRight)}>
                                            {formatDecimal(line.price)}
                                        </TableCell>
                                        <TableCell className={classNames(styles.invoiceRow, styles.alignRight)}>
                                            {formatDecimal(line.lineTotal)}
                                        </TableCell>
                                        <TableCell className={classNames(styles.invoiceRow, styles.alignRight)}>
                                            {line.vatPercentage ? line.vatPercentage + " %" : "-"}
                                        </TableCell>
                                        <TableCell className={classNames(styles.invoiceRow, styles.alignRight)}>
                                            {formatDecimal(line.lineSum)}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            <TableRow className={styles.invoiceGroupEnd}>
                                {[...Array(refundMode ? 11 : 10)].map((value, index) => (
                                    <TableCell key={index}></TableCell>
                                ))}
                                <TableCell className={classNames(styles.cellPadding, styles.alignRight)}>
                                    {formatDecimal(invoicesNetSum)}
                                </TableCell>
                                <TableCell className={classNames(styles.cellPadding, styles.alignRight)} colSpan={2}>
                                    {formatDecimal(invoicesSum)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            )}
        </Paper>
    )
}

export { ContractInvoices }
