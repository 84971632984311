import { InvoiceParty } from "models/create-invoice"

export const getFractionString = (numerator: number, denominator: number) => {
    if (numerator === 0 || denominator === 0) {
        return "0"
    }

    if (Math.abs(numerator / denominator - Math.floor(numerator / denominator)) < Number.EPSILON) {
        return (numerator / denominator).toString()
    }
    return `${numerator}/${denominator}`
}

export const getDecimalString = (price: number) => {
    return price.toLocaleString("en-US", { minimumFractionDigits: 2 })
}

export const getInvoicePartyAddressFormatted = (party: InvoiceParty | undefined) => {
    if (!party || !party.address || !party.address.streetAddress) return ""

    return party.address.postalCode
        ? `${party.address.streetAddress}, ${party.address.postalCode}`
        : party.address.streetAddress
}
