import { Grid } from "@material-ui/core"
import React from "react"
import { Field } from "redux-form"
import { TEXT_TRIPLETEX_API_URL, TEXT_TRIPLETEX_CONSUMER_TOKEN, TEXT_TRIPLETEX_EMPLOYEE_TOKEN } from "utils/translations/keys"
import { tr } from "utils/translations/translate"
import { BillingTextFieldAdapter } from "common/textField/billingTextField"

export const TripletexConfigControls = props => {
    const { fieldWidth, editMode } = props

    return (
        <Grid container spacing={4} direction="row">
            <Grid item xs={fieldWidth}>
                <Field
                    editMode={editMode}
                    name="tripletexConfiguration.apiBaseUrl"
                    component={BillingTextFieldAdapter}
                    label={tr(TEXT_TRIPLETEX_API_URL)}
                />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={fieldWidth}>
                <Field
                    editMode={editMode}
                    name="tripletexConfiguration.consumerToken"
                    component={BillingTextFieldAdapter}
                    label={tr(TEXT_TRIPLETEX_CONSUMER_TOKEN)}
                />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={fieldWidth}>
                <Field
                    editMode={editMode}
                    name="tripletexConfiguration.employeeToken"
                    component={BillingTextFieldAdapter}
                    label={tr(TEXT_TRIPLETEX_EMPLOYEE_TOKEN)}
                />
            </Grid>
            <Grid item xs={8}></Grid>
        </Grid>
    )
}
