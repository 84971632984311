import { Contract, ContractListItem } from "models/contract"
import { tr } from "utils/translations/translate"
import { TEXT_CONTRACT } from "utils/translations/keys"

export const getMatrikkelNr = (gnr: string, bnr: string, fnr: string, snr: string) => {
    let matrikkelNr = gnr.toString()
    if (bnr !== "0" || fnr !== "0" || snr !== "0") {
        matrikkelNr += `/${bnr}`
    }
    if (fnr !== "0" || snr !== "0") {
        matrikkelNr += `/${fnr}`
    }
    if (snr !== "0") {
        matrikkelNr += `/${snr}`
    }
    return matrikkelNr
}

export const getContractMatrikkelNumberLong = (contract: Contract | ContractListItem | null) => {
    return !!contract
        ? `${!!contract.cityId ? `${String(contract.cityId).padStart(4, "0")}- ` : ""}
          ${getMatrikkelNr(contract.gnr, contract.bnr, contract.fnr, contract.snr)}`
        : tr(TEXT_CONTRACT)
}

export const getContractOrganizationNumber = (contract: Contract | ContractListItem | null) => {
    return !!contract ? contract.gnr : tr(TEXT_CONTRACT)
}
