import {
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { InvoiceType } from "common/enums/invoiceType"
import Spinner from "common/spinner"
import { InvoicesSendResult } from "models/invoices-send-result"
import { User } from "models/user"
import { ContractDialog } from "pages/contract-page/contract-dialog"
import React from "react"
import {
    TEXT_ACTIVITY_BASED_INVOICES,
    TEXT_ALL_INVOICE_TYPES,
    TEXT_GROUPING_TYPE_BY_PRODUCT,
    TEXT_GROUP_BY_BUILDING_NUMBER,
    TEXT_INVOICES_FAILED_SENDING_TO_ERP,
    TEXT_INVOICES_SENT_SUCCESSFULLY_TO_ERP,
    TEXT_INVOICE_TYPE,
    TEXT_NEW_INVOICES_CREATED_BY_USER,
    TEXT_OK,
    TEXT_PERIODIC_COST_TYPE_NORMAL,
    TEXT_PERIODIC_COST_TYPE_RUNNING,
    TEXT_PERIODIC_INVOICES,
    TEXT_SEND_TO_ERP,
    TEXT_YES,
} from "utils/translations/keys"
import { tr } from "utils/translations/translate"
import {
    ACTIVITY_BASED_INVOICE_TYPE,
    ALL_INVOICE_TYPES,
    GROUPING_TYPE_BY_PRODUCT,
    GROUPING_TYPE_NONE,
    PERIODIC_INVOICE_TYPE,
    PERIODIC_TYPE_NORMAL,
    PERIODIC_TYPE_RUNNING,
    USER_INVOICE_TYPE,
} from "./dashboard-constants"

export const SendToErpDialog: React.FC<{
    invoicesSendResult: InvoicesSendResult
    isSendToErpDialogOpen: boolean
    sendToErp: () => void
    styles: any
    inputNumberOfPaymentInvoicesToSend: string
    numberOfPaymentInvoicesToSend: number
    setInputNumberOfPaymentInvoicesToSend: (value: string) => void
    handleSendToErpDialogClose: () => void
    isKomtek: boolean
    onInvoiceTypeChange: (value: unknown) => void
    invoiceType: InvoiceType
    handleChangeGroupingType: (value: string) => void
    isSendToErpRunning: boolean
    user: User | undefined
    handleChangePeriodicType: (value: string) => void
    groupingType: number
    periodicCostType: number
    sendToErpFinished: boolean
    showInvoiceCount: boolean
}> = props => {
    const {
        invoicesSendResult,
        isSendToErpDialogOpen,  
        sendToErp,
        styles,
        inputNumberOfPaymentInvoicesToSend,
        numberOfPaymentInvoicesToSend,
        setInputNumberOfPaymentInvoicesToSend,
        handleSendToErpDialogClose,
        isKomtek,
        onInvoiceTypeChange,
        invoiceType,
        handleChangeGroupingType,
        isSendToErpRunning,
        user,
        handleChangePeriodicType,
        groupingType,
        periodicCostType,
        sendToErpFinished,
        showInvoiceCount
    } = props

    const processNotStarted = invoicesSendResult.successCount === 0 && invoicesSendResult.failureCount === 0

    return (
        <ContractDialog
            title={tr(TEXT_SEND_TO_ERP)}
            open={isSendToErpDialogOpen}
            handleClose={handleSendToErpDialogClose}
            handleOk={sendToErpFinished ? handleSendToErpDialogClose : () => sendToErp()}
            okText={sendToErpFinished ? tr(TEXT_OK) : tr(TEXT_SEND_TO_ERP)}
            showOk={!isSendToErpRunning}
        >
            {invoicesSendResult && numberOfPaymentInvoicesToSend > 0 && (
                <>
                    {processNotStarted && (
                        <>
                            {showInvoiceCount && 
                            <div>
                                {`Run process to send ${numberOfPaymentInvoicesToSend} invoices to the ERP system`}
                            </div>}
                            <div>
                                <Grid container spacing={2} className={styles.sendToErpContainer}>
                                    {user?.godMode && (
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Invoice count"
                                                type="number"
                                                InputProps={{ inputProps: { min: 0, style: { textAlign: "right" } } }}
                                                value={inputNumberOfPaymentInvoicesToSend}
                                                onChange={item => {
                                                    setInputNumberOfPaymentInvoicesToSend(item.target.value)
                                                }}
                                            ></TextField>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <InputLabel id="select-invoice-type-input-label">
                                            {tr(TEXT_INVOICE_TYPE)}
                                        </InputLabel>
                                        <Select
                                            labelId="select-invoice-type-input-label"
                                            onChange={e => onInvoiceTypeChange(e.target.value)}
                                            value={invoiceType}
                                        >
                                            <MenuItem key={0} value={ALL_INVOICE_TYPES}>
                                                {tr(TEXT_ALL_INVOICE_TYPES)}
                                            </MenuItem>
                                            <MenuItem key={1} value={PERIODIC_INVOICE_TYPE}>
                                                {tr(TEXT_PERIODIC_INVOICES)}
                                            </MenuItem>
                                            <MenuItem key={2} value={ACTIVITY_BASED_INVOICE_TYPE}>
                                                {tr(TEXT_ACTIVITY_BASED_INVOICES)}
                                            </MenuItem>
                                            <MenuItem key={3} value={USER_INVOICE_TYPE}>
                                                {tr(TEXT_NEW_INVOICES_CREATED_BY_USER)}
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    {isKomtek && invoiceType === PERIODIC_INVOICE_TYPE && (
                                        <>
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    aria-label="periodic type"
                                                    name="periodicType"
                                                    onChange={e => handleChangePeriodicType(e.target.value)}
                                                    value={periodicCostType}
                                                >
                                                    <FormControlLabel
                                                        value={PERIODIC_TYPE_RUNNING}
                                                        control={<Radio />}
                                                        label={tr(TEXT_PERIODIC_COST_TYPE_RUNNING)}
                                                    />
                                                    <FormControlLabel
                                                        value={PERIODIC_TYPE_NORMAL}
                                                        control={<Radio />}
                                                        label={tr(TEXT_PERIODIC_COST_TYPE_NORMAL)}
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>{tr(TEXT_GROUPING_TYPE_BY_PRODUCT)}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    aria-label="grouping type"
                                                    name="groupingType"
                                                    onChange={e => handleChangeGroupingType(e.target.value)}
                                                    value={groupingType}
                                                >
                                                    <FormControlLabel
                                                        value={GROUPING_TYPE_BY_PRODUCT}
                                                        control={<Radio />}
                                                        label={tr(TEXT_YES)}
                                                    />
                                                    <FormControlLabel
                                                        value={GROUPING_TYPE_NONE}
                                                        control={<Radio />}
                                                        label={tr(TEXT_GROUP_BY_BUILDING_NUMBER)}
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </div>
                        </>
                    )}
                </>
            )}
            {invoicesSendResult && invoicesSendResult.successCount > 0 && (
                <p>{`${invoicesSendResult.successCount} ${tr(TEXT_INVOICES_SENT_SUCCESSFULLY_TO_ERP)}`}</p>
            )}
            {invoicesSendResult && invoicesSendResult.failureCount > 0 && (
                <span style={{ color: "red" }}>
                    {`${invoicesSendResult.failureCount} ${tr(TEXT_INVOICES_FAILED_SENDING_TO_ERP)}`}
                    <br />
                    {Object.entries(invoicesSendResult.problemCategories)
                        .slice(0, 5)
                        .map(([key, value]) => (
                            <p key={key}>{`(${value.length}) ${key}`}</p>
                        ))}
                    {Object.entries(invoicesSendResult.problemCategories).length > 5 && (
                        <p>.. and {Object.entries(invoicesSendResult.problemCategories).length - 5} more errors</p>
                    )}
                </span>
            )}
            {isSendToErpRunning && <Spinner />}
        </ContractDialog>
    )
}
