import { Address } from "models/contract"

export const getAddressFormatted = (emptyPlaceholder: string, address?: Address) => {
    return address
        ? `${address?.streetAddress ? `${address?.streetAddress}, ` : ""}${address?.postalCode ?? "-"} 
        ${address?.city ?? ""}`
        : emptyPlaceholder
}

export const formatDecimal = (value: number) => {
    return value.toLocaleString("en-US", { minimumFractionDigits: 2 })
}
