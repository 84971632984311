import React from "react"
import { CubitTableColumn } from "shared-components/src/cubit-table/cubit-table.types"
import CubitTable from "shared-components/src/cubit-table/cubit-table"
import { tr } from "utils/translations/translate"
import {
    TEXT_GNR_BNR,
    TEXT_OWNER_FULL_NAME,
    TEXT_ADDRESS,
    TEXT_POST_ADDRESS,
    TEXT_CONTRACT,
    TEXT_ACTIVE_PRODUCTS,
    TEXT_SERIALNUMBER,
    TEXT_OWNER_RECIPIENT,
    TEXT_ORGANIZATION_NUMBER,
} from "utils/translations/keys"
import { Address, Contract, ContractListItem } from "models/contract"
import { navigate } from "@reach/router"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import { getContractMatrikkelNumberLong, getContractOrganizationNumber } from "utils/contract-utils"
import { useSelector } from "react-redux"
import { AppState } from "redux/app/app-store"
import App from "app/App"

const useStyles = makeStyles((theme: Theme) => ({
    contractsTitle: {
        padding: theme.spacing(2),
        background: "white",
        width: "100%",
        display: "inline-block",
        boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
    },
    titleCase: {
        textTransform: "capitalize",
    },
}))

type ContractTableProps = {
    contracts: ContractListItem[]
}

export const ContractTable: React.FC<ContractTableProps> = ({ contracts }) => {
    const styles = useStyles()
    const isNsoTenant = useSelector((state: AppState) => state.config.tenantConfig?.isNsoTenant)

    const commonContractColumns: CubitTableColumn[] = [
        {
            headerLabel: tr(TEXT_SERIALNUMBER),
            key: "serialNumber",
            getDisplayableElement: (row: ContractListItem) => {
                if (!row.serialNumber || row.serialNumber.length == 0) {
                    return <span></span>
                }
                return <span>{row.serialNumber}</span>
            },
        },
        {
            headerLabel: tr(TEXT_ADDRESS),
            key: "address.streetAddress",
            getDisplayableElement: (row: ContractListItem) => {
                if (!row.address) {
                    return <span></span>
                }
                return (
                    <span>
                      {renderAddress(row.address)}
                    </span>
                )
            },
        },
        {
            headerLabel: tr(TEXT_OWNER_FULL_NAME),
            key: "ownerFullName",
            getDisplayableElement: (row: ContractListItem) => {
                return <span className={styles.titleCase}>{row.ownerFullName?.toLowerCase()}</span>
            },
        },
        {
            headerLabel: tr(TEXT_OWNER_RECIPIENT),
            key: "invoiceRecipientFullName",
            getDisplayableElement: (row: ContractListItem) => {
                return <span className={styles.titleCase}>{row.invoiceRecipientFullName?.toLowerCase()}</span>
            },
        },
        {
            headerLabel: tr(TEXT_POST_ADDRESS),
            key: "postAddress",
            getDisplayableElement: (row: ContractListItem) => {
                if (!row.address) {
                    return <span></span>
                }
                return (
                    <span>
                      {renderAddress(row.postAddress)}
                    </span>
                )
            },
        },
        {
            headerLabel: tr(TEXT_ACTIVE_PRODUCTS),
            key: "activeProducts",
        },
    ]

    const renderAddress = (address: Address | undefined) => {
      const street = address?.streetAddress ? `${address.streetAddress}, ` : ''
      const appartment = address?.apartmentNumber ? `${address.apartmentNumber}, ` : '' 
      const postalCode = address?.postalCode ? `${address.postalCode}` : '' 
      const city = address?.city ? ` ${address.city}` : ''

      return `${street}${appartment}${postalCode}${city}`
    }

    const nsoExcludeColumns = ['postAddress']

    const contractColumns = isNsoTenant
        ? [
              {
                  headerLabel: tr(TEXT_ORGANIZATION_NUMBER),
                  key: "gnr",
                  getDisplayableElement: (row: ContractListItem) => <span>{getContractOrganizationNumber(row)}</span>,
              },
              ...commonContractColumns.filter(x => !nsoExcludeColumns.find(f => f === x.key)) 
          ]
        : [
              {
                  headerLabel: tr(TEXT_GNR_BNR),
                  key: "gnr",
                  getDisplayableElement: (row: ContractListItem) => <span>{getContractMatrikkelNumberLong(row)}</span>,
              },
              ...commonContractColumns,
          ]

    const handleRowClick = (row: Contract) => {
        navigate(`/contracts/${row.id}`)
    }

    return (
        <>
            <strong className={styles.contractsTitle}>{tr(TEXT_CONTRACT)}</strong>
            <CubitTable
                name="contracts"
                columns={contractColumns}
                data={contracts}
                sidePadding="24"
                //sorting={{ direction: "asc", by: "gnr" }} //TODO: fix sort by matrikkel number. Currently relying on backend sort.
                onRowClick={handleRowClick}
                paging={{ rowsPerPage: [50] }}
            />
        </>
    )
}
