import { BillingConfiguration } from "models/billing-configuration"
import { InvoiceFile, FileType } from "models/invoice-file"
import { InvoicePeriodStatistic } from "models/invoice-period-statistic"
import { InvoiceStatistic } from "models/invoice-statistic"
import { InvoicesSendResult } from "models/invoices-send-result"
import {
    ACTIVITY_BASED_INVOICE_TYPE,
    ALL_INVOICE_TYPES,
    GROUPING_TYPE_BY_BUILDING,
    GROUPING_TYPE_BY_PRODUCT,
    PERIODIC_INVOICE_TYPE,
    USER_INVOICE_TYPE,
} from "pages/dashboard-page/dashboard-constants"
import { Observable } from "rxjs"
import { apiUrl, httpGet, httpGetFile, httpPost } from "services/httpService"

export default class DashboardClient {
    public static getInvoices(): Observable<InvoiceStatistic> {
        return httpGet<InvoiceStatistic>(apiUrl(`/Dashboard/Invoices`))
    }
    public static getInvoiceFiles(): Observable<InvoiceFile[]> {
        return httpGet<InvoiceFile[]>(apiUrl(`/Dashboard/InvoiceFiles`))
    }
    public static getInvoiceFile(type: FileType, id: string): Promise<any> {
        return httpGetFile(apiUrl(`/Dashboard/DownloadInvoiceFile?type=${type}&id=${id}`))
    }
    public static getConfiguration(): Observable<BillingConfiguration> {
        return httpGet<BillingConfiguration>(apiUrl(`/Dashboard/Configuration`))
    }
    public static getInvoicePeriodStatistics(): Observable<InvoicePeriodStatistic[]> {
        return httpGet<InvoicePeriodStatistic[]>(apiUrl(`/Dashboard/InvoicePeriodStatistics`))
    }
    public static sendPeriodToErp(
        year: number,
        period: number,
        invoiceType: number,
        periodicCostType: number,
        groupingType: number,
        limit?: number
    ): Observable<InvoicesSendResult> {
        return httpPost<InvoicesSendResult>(apiUrl("/Dashboard/SendPeriodToErp"), {
            year: year,
            period: period,
            selectedInvoiceTypes:
                invoiceType === ALL_INVOICE_TYPES
                    ? [PERIODIC_INVOICE_TYPE, ACTIVITY_BASED_INVOICE_TYPE, USER_INVOICE_TYPE]
                    : [invoiceType],
            periodicCostType: periodicCostType,
            groupByProductEnabled: groupingType === GROUPING_TYPE_BY_PRODUCT ? true : false,
            limit: limit,
        })
    }

    public static generateDiffFile(year: number, period: number, groupingType: number): Observable<InvoicesSendResult> {
        return httpPost<InvoicesSendResult>(apiUrl("/Dashboard/GenerateDiffFile"), {
            year: year,
            period: period,
            groupByBuilding: groupingType === GROUPING_TYPE_BY_BUILDING,
        })
    }

    public static refreshPeriodStatistics(year: number, period: number): Observable<InvoicePeriodStatistic> {
        return httpGet<InvoicePeriodStatistic>(
            apiUrl(`/Dashboard/RefreshPeriodStatistics?year=${year}&period=${period}`)
        )
    }
    public static getInvoiceSummary(year: number, period: number): Promise<any> {
        return httpGetFile(apiUrl(`/Dashboard/GetPeriodInvoiceSummary?year=${year}&period=${period}`))
    }
    public static migrateData(): Observable<void> {
        return httpGet<void>(apiUrl(`/ContractMigration/migrateContracts`))
    }
}
