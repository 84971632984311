export const ALL_INVOICE_TYPES = 0
export const PERIODIC_INVOICE_TYPE = 1
export const ACTIVITY_BASED_INVOICE_TYPE = 3
export const USER_INVOICE_TYPE = 4

export const PERIODIC_TYPE_NORMAL = 1
export const PERIODIC_TYPE_RUNNING = 2

export const GROUPING_TYPE_NONE = 0
export const GROUPING_TYPE_BY_PRODUCT = 1
export const GROUPING_TYPE_BY_BUILDING = 2
