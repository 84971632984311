import React, { useState } from "react";
import { tr } from "../../utils/translations/translate";
import {
  TEXT_PRICE,
  TEXT_QUANTITY,
  TEXT_UTILITY_UNIT,
  TEXT_UTILITYUNITS_AND_PRODUCTS,
  TEXT_PRODUCTS,
  TEXT_MVA,
  TEXT_SUM
} from "../../utils/translations/keys";
import {
  makeStyles,
  Paper, TextField,
  Theme
} from "@material-ui/core";
import { ContractProduct, ContractProductEditModel, ResidentialInfo } from "models/contract";
import { CubitTableColumn } from "shared-components/src/cubit-table/cubit-table.types";
import CubitTable from "shared-components/src/cubit-table/cubit-table";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/app/app-store";
import { Product } from "../../models/product";
import { ContractProductUpdateSet } from "../../models/contract-product-update-set";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    paddingTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  contractsTitle: {
    padding: "1rem 1rem 0 16px",
    background: "white",
    width: "100%",
    display: "inline-block"
  },
  checkbox: {
    padding: 0,
  },
  alignRight: {
    textAlign: "right"
  },
  headerColumn: {
    padding: "14px 0px 16px 16px"
  },
  separator: {
    lineHeight: "2rem",
    marginInline: "5px"
  },
  columnPadding:{
    padding: "10px"
  },
}));

type ContractProductsEdit = {
  residentialInfo: ResidentialInfo[],
  isProductBasedContract: boolean,
  onProductsUpdated?: (updateSet: ContractProductUpdateSet[]) => void,
}

const ContractProductsEdit: React.FC<ContractProductsEdit> = (props) => {
  const {
    residentialInfo,
    isProductBasedContract,
    onProductsUpdated
  } = props;

  const styles = useStyles();

  const [ updateCount, setUpdateCount ] = useState(0);
  const [ denominatorValue, setDenominatorValue ] = useState(1);
  const [ productsUpdateSet, setProductsUpdateSet ] = useState(new Map<string, ContractProductUpdateSet>());

  const forceUpdate = () => {
    return setUpdateCount(updateCount => updateCount + 1);
  }

  const originalProducts = residentialInfo.flatMap((x, i) => (x.products.length > 0 ? x.products : [ {} as ContractProduct ]).map(p => ({ ...p, utilityUnit: `${x.address} ${x.residentialNumber}`, companyName: x.name }))) as ContractProduct[];
  const originalProductsMap = new Map(originalProducts.map(x => [ `${x.utilityUnit}|${x.productId}`, x ]));

  const allProducts = useSelector((state: AppState) => state.products.items as Product[]) || [];
  const activeProducts = allProducts.filter(x => (x.status || {}).value === 1);

  const onNumeratorChanged = (product: ContractProductEditModel, numerator: number) => {
    const key = `${product.utilityUnit}|${product.productId}`;
    if (!productsUpdateSet.has(key)) {
      productsUpdateSet.set(key, {
        utilityUnit: product.utilityUnit,
        productId: product.productId,
        quantity: product.quantity,
        numerator: numerator,
        denominator: product.denominator
      } as ContractProductUpdateSet)
    } else {
      const updateSet = productsUpdateSet.get(key) || {} as ContractProductUpdateSet;
      updateSet.numerator = numerator;
    }
    processChanges(product);
  }

  const onDenominatorChanged = (product: ContractProductEditModel, denominator: number) => {
    const key = `${product.utilityUnit}|${product.productId}`;
    if (!productsUpdateSet.has(key)) {
      productsUpdateSet.set(key, {
        utilityUnit: product.utilityUnit,
        productId: product.productId,
        quantity: product.quantity,
        numerator: product.numerator,
        denominator: denominator
      } as ContractProductUpdateSet)
    } else {
      const updateSet = productsUpdateSet.get(key) || {} as ContractProductUpdateSet;
      updateSet.denominator = denominator;
    }
    processChanges(product);
  }

  const processChanges = (product: ContractProductEditModel) => {
    const key = `${product.utilityUnit}|${product.productId}`;
    const updateSet = productsUpdateSet.get(key) || {} as ContractProductUpdateSet;
    const originalProduct = originalProductsMap.get(key);
    if (!!originalProduct) {
      if (originalProduct.numerator === updateSet.numerator && originalProduct.denominator === updateSet.denominator && originalProduct.quantity === updateSet.quantity) {
        productsUpdateSet.delete(key);
      }
    } else {
      if (updateSet.numerator === 0 || updateSet.quantity === 0) {
        productsUpdateSet.delete(key);
      }
    }

    if (onProductsUpdated) {
      onProductsUpdated(Array.from(productsUpdateSet.values()));
      forceUpdate();
    }
  }

  const columns: CubitTableColumn[] = [
    {
      headerLabel: tr("TEXT_PRD_NO"),
      key: "productId",
      width: "8%",
      getDisplayableElement: row => (
        <>{row?.productId}</>
      ),
      bodyClassName: `${styles.alignRight}`,
      paddingClassName: styles.columnPadding,
      headerClassName: `${styles.headerColumn} ${styles.alignRight}`
    },
    {
      headerLabel: tr("TEXT_PRODUCT_NAME"),
      key: "name",
      width: props.isProductBasedContract ? "46%" : "23%",
      paddingClassName: styles.columnPadding,
      headerClassName: styles.headerColumn
    },
    {
      headerLabel: tr(TEXT_QUANTITY),
      key: "percentageRepresentation",
      width: "11%",
      sortable: true,
      bodyClassName: `${styles.alignRight}`,
      paddingClassName: styles.columnPadding,
      headerClassName: `${styles.headerColumn} ${styles.alignRight}`,
      getDisplayableElement: row => (<>
        {!row.isReadonly && <div style={{ display: 'inline-flex' }}>
          <TextField type="number" InputProps={{ inputProps: { min: 0, style: { textAlign: 'right' } }, error: (productsUpdateSet.get(row.productId)===undefined ? false : (productsUpdateSet.get(row.productId)?.numerator || 0) < 0) }} defaultValue={row.numerator} onChange={item => onNumeratorChanged(row, parseInt(item.target.value))}></TextField>
          <span className={styles.separator}>/</span>
          <TextField type="number" InputProps={{ inputProps: { min: 1, style: { textAlign: 'right' } }, error: (productsUpdateSet.get(row.productId)===undefined ? false : (productsUpdateSet.get(row.productId)?.denominator || 0) < 1) }} defaultValue={row.denominator} onChange={item => onDenominatorChanged(row, parseInt(item.target.value))}></TextField>
        </div>}
        {row.isReadonly && <span>{row.percentageRepresentation}</span>}
      </>)
    },
    {
      headerLabel: tr(TEXT_PRICE),
      key: "price",
      width: "15%",
      getDisplayableElement: row => (
        <>{row.price?.toLocaleString('en-US', { minimumFractionDigits: 2 })}</>
      ),
      paddingClassName: styles.columnPadding,
      bodyClassName: `${styles.alignRight}`,
      headerClassName: `${styles.headerColumn} ${styles.alignRight}`
    },
    {
      headerLabel: tr(TEXT_MVA),
      key: "vat",
      width: "16%",
      getDisplayableElement: row => (
        <span>{row.vatPercentage} %</span>
      ),
      paddingClassName: styles.columnPadding,
      bodyClassName: `${styles.alignRight}`,
      headerClassName: `${styles.headerColumn} ${styles.alignRight}`
    },
    {
      headerLabel: tr(TEXT_SUM),
      key: "lineSum",
      width: "8%",
      getDisplayableElement: row => (<>
          {!productsUpdateSet.has(row.productId) && <>{row.lineSum?.toLocaleString('en-US', { minimumFractionDigits: 2 })}</>}
        </>
      ),
      paddingClassName: styles.columnPadding,
      bodyClassName: `${styles.alignRight}`,
      headerClassName: `${styles.headerColumn} ${styles.alignRight}`
    }
  ];

  if (!isProductBasedContract) {
    columns.unshift({
      headerLabel: tr(TEXT_UTILITY_UNIT),
      key: "utilityUnit",
      paddingClassName: styles.columnPadding,
      width: "23%",
      headerClassName: styles.headerColumn
    })
  }

  const initProductEditModel = (activeProduct: Product, utilityUnit: string) => {
    return ({
      utilityUnit: utilityUnit,
      name: activeProduct.name,
      productId: activeProduct.productId,
      price: activeProduct.price.toString(),
      lineSum: 0,
      quantity: 1,
      numerator: 0,
      denominator: 1,
      vatCode: activeProduct.vatCode,
      vatPercentage: activeProduct.vatPercentage
    } as unknown) as ContractProductEditModel
  }

  let allProductsToEdit = [] as ContractProductEditModel[];

  if(residentialInfo.length === 0){
    activeProducts.forEach(activeProduct => {
      allProductsToEdit.push(initProductEditModel(activeProduct, ''));
    });
  }
  else {

    const productsToEdit = residentialInfo.flatMap((x, i) => (x.products.length > 0 ? x.products : [ {} as ContractProductEditModel ])
    .map(p => ({ ...p, utilityUnit: `${x.address} ${x.residentialNumber}`, companyName: x.name }))) as ContractProductEditModel[];

    activeProducts.forEach(activeProduct => {
      const existingProductAsCollection = (productsToEdit.filter(x => x.productId === activeProduct.productId) || []);
      if (existingProductAsCollection.length > 0) {
        const existingProduct = existingProductAsCollection[0];
        existingProduct.isReadonly = false;
      } else {
        productsToEdit.push(initProductEditModel(activeProduct, ''));
      }
    });

    allProductsToEdit = allProductsToEdit.concat(productsToEdit);
  }

  return (
    <Paper className={styles.paper}>
      <h3 className={styles.contractsTitle}>{props.isProductBasedContract ? tr(TEXT_PRODUCTS) : tr(TEXT_UTILITYUNITS_AND_PRODUCTS)}</h3>
      <CubitTable
        flat={true}
        name="contract-products"
        columns={columns}
        data={allProductsToEdit}
        sorting={props.isProductBasedContract ? { direction: "desc", by: "name" } : { direction: "desc", by: "utilityUnit" }}
      />
    </Paper>
  )
};

export { ContractProductsEdit };
