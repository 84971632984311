import React, { useEffect, useState } from "react"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import ErrorIcon from "@material-ui/icons/Error"
import { tr } from "../../utils/translations/translate"
import {
    TEXT_DASHBOARD_INVOICE_UPDATE_NOTIFICATION_TEXT,
    TEXT_DASHBOARD_INVOICE_UPDATE_NOTIFICATION_TITLE,
    TEXT_INVOICES_UPDATING,
    TEXT_UPDATE_INVOICES,
} from "./translations/keys"
import { TEXT_PRODUCTS, TEXT_TERMS } from "utils/translations/keys"
import LinearProgress from "@material-ui/core/LinearProgress"
import { BillingJobProgress, JobStatus, UpdatedEntityType } from "models/billing-jobs"

export type InvoiceUpdateProps = {
    title?: string
    notificationTexts?: string[]
    invoiceUpdateResponse?: BillingJobProgress | null
    showUpdatedEntities?: boolean
    updateInvoices: any
}

const MessageBackgroundColor = "#d3e6ea"

const useStyles = makeStyles((theme: Theme) => ({
    alertBox: {
        color: theme.palette.text.primary,
        backgroundColor: MessageBackgroundColor,
        border: "1px solid " + theme.palette.primary.main,
        marginBottom: 16,
    },
    alertBoxContainer: {
        margin: "0 8px 16px 8px",
    },
    progressBoxContainer: {
        margin: "0 8px 16px 8px",
        textAlign: "center",
        paddingBottom: 20,
        paddingTop: 10,
        justifyContent: "center",
    },
    progressBar: {
        maxWidth: "60%",
    },
    progressText: {
        margin: 10,
    },
}))

const getEntityTranslation = (entity: UpdatedEntityType) => {
    switch (entity) {
        case UpdatedEntityType.Products:
            return tr(TEXT_PRODUCTS)
        case UpdatedEntityType.Periods:
            return tr(TEXT_TERMS)
        default:
            return entity
    }
}

const InvoiceUpdateProgressNotification: React.FC<InvoiceUpdateProps> = props => {
    const {
        title = tr(TEXT_DASHBOARD_INVOICE_UPDATE_NOTIFICATION_TITLE),
        notificationTexts = [`${tr(TEXT_DASHBOARD_INVOICE_UPDATE_NOTIFICATION_TEXT)}:`],
        invoiceUpdateResponse = props.invoiceUpdateResponse,
    } = props

    const styles = useStyles()
    
    const [showInProgress, setShowInProgress] = useState(false)

    useEffect(() => {
      
        setShowInProgress(invoiceUpdateResponse?.billingJobState?.jobStatus === JobStatus.Running ||
        invoiceUpdateResponse?.billingJobState?.jobStatus === JobStatus.NotStarted)
      
    }, [invoiceUpdateResponse?.billingJobState?.jobStatus])
    
    const update = () => {
        setShowInProgress(true)
        props.updateInvoices()
    }
    
    return showInProgress ? (
        <Grid container alignItems="center" className={styles.progressBoxContainer}>
            <Grid item xs={12} className={styles.progressText}>
                <Typography>{tr(TEXT_INVOICES_UPDATING)}</Typography>
            </Grid>
            <Grid item xs={12} className={styles.progressBar}>
                <LinearProgress></LinearProgress>
            </Grid>
        </Grid>
    ) : (
        <div className={styles.alertBoxContainer}>
            <Alert
                variant="outlined"
                severity="info"
                className={styles.alertBox}
                icon={<ErrorIcon fontSize="inherit" color="primary" />}
            >
                <AlertTitle>
                    <strong>{title}</strong>
                </AlertTitle>
                {notificationTexts && notificationTexts.map((t, i) => <span key={i}>{t}</span>)}
                {props.showUpdatedEntities &&
                    invoiceUpdateResponse?.updatedEntityTypes &&
                    invoiceUpdateResponse?.updatedEntityTypes.length > 0 && (
                        <ul>
                            {invoiceUpdateResponse?.updatedEntityTypes.map((e, i) => (
                                <li key={i}>
                                    <strong>{getEntityTranslation(e)}</strong>
                                </li>
                            ))}
                        </ul>
                    )}
            </Alert>
            <Button variant="contained" color="primary" onClick={update}>
                {tr(TEXT_UPDATE_INVOICES)}
            </Button>
        </div>
    )
}

export default InvoiceUpdateProgressNotification
