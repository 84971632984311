import React, {  } from "react"
import { tr } from "../../utils/translations/translate"
import {
    TEXT_PRICE,
    TEXT_QUANTITY,
    TEXT_UTILITY_UNIT,
    TEXT_UTILITYUNITS_AND_PRODUCTS,
    TEXT_MVA,
    TEXT_SUM,
    TEXT_PRODUCTS,
    TEXT_ADDRESS,
    TEXT_NAME,
    TEXT_ORGANIZATIONS_AND_PRODUCTS,
} from "../../utils/translations/keys"
import { makeStyles, Paper, Theme } from "@material-ui/core"
import { ContractProduct, ResidentialInfo } from "models/contract"
import { CubitTableColumn } from "shared-components/src/cubit-table/cubit-table.types"
import CubitTable from "shared-components/src/cubit-table/cubit-table"

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    contractsTitle: {
        padding: "1rem 1rem 0 16px",
        background: "white",
        width: "100%",
        display: "inline-block",
    },
    checkbox: {
        padding: 0,
    },
    alignRight: {
        textAlign: "right",
    },
    columnPadding: {
        padding: "10px",
    },
    headerColumn: {
        padding: "14px 0px 16px 16px",
    },
}))

type ContractProducts = {
    residentialInfo: ResidentialInfo[]
    isProductBasedContract: boolean
    isNso: boolean
}

const ContractProducts: React.FC<ContractProducts> = props => {
    const { residentialInfo, isProductBasedContract, isNso } = props

    const styles = useStyles()

    const columns: CubitTableColumn[] = [
        {
            headerLabel: tr("TEXT_PRD_NO"),
            key: "productId",
            width: "8%",
            getDisplayableElement: row => <>{row?.productId}</>,
            bodyClassName: `${styles.alignRight}`,
            paddingClassName: styles.columnPadding,
            headerClassName: `${styles.headerColumn} ${styles.alignRight}`,
        },
        {
            headerLabel: tr("TEXT_PRODUCT_NAME"),
            key: "name",
            width: props.isProductBasedContract ? "46%" : "23%",
            getDisplayableElement: row => <>{!!row.name ? row.name : "No active product"}</>,
            headerClassName: styles.headerColumn,
        },
        {
            headerLabel: tr(TEXT_QUANTITY),
            key: "percentageRepresentation",
            width: "11%",
            bodyClassName: `${styles.alignRight}`,
            paddingClassName: styles.columnPadding,
            headerClassName: `${styles.headerColumn} ${styles.alignRight}`,
        },
        {
            headerLabel: tr(TEXT_PRICE),
            key: "price",
            width: "15%",
            getDisplayableElement: row => <>{row.price?.toLocaleString("en-US", { minimumFractionDigits: 2 })}</>,
            bodyClassName: `${styles.alignRight}`,
            paddingClassName: styles.columnPadding,
            headerClassName: `${styles.headerColumn} ${styles.alignRight}`,
        },
        {
            headerLabel: tr(TEXT_MVA),
            key: "vat",
            width: "16%",
            getDisplayableElement: row => <span>{!!row.vatPercentage ? `${row.vatPercentage} %` : ""}</span>,
            bodyClassName: `${styles.alignRight}`,
            paddingClassName: styles.columnPadding,
            headerClassName: `${styles.headerColumn} ${styles.alignRight}`,
        },
        {
            headerLabel: tr(TEXT_SUM),
            key: "lineSum",
            width: "6%",
            getDisplayableElement: row => <>{row.lineSum?.toLocaleString("en-US", { minimumFractionDigits: 2 })}</>,
            bodyClassName: `${styles.alignRight}`,
            paddingClassName: styles.columnPadding,
            headerClassName: `${styles.headerColumn} ${styles.alignRight}`,
        },
    ]

    if (!props.isProductBasedContract && !isNso) {
        columns.unshift({
            headerLabel: tr(TEXT_UTILITY_UNIT),
            width: "23%",
            key: "utilityUnit",
            headerClassName: styles.headerColumn,
            paddingClassName: styles.columnPadding,
        })
    }

    if (isNso) {
        columns.unshift(
            {
                headerLabel: tr(TEXT_NAME),
                width: "23%",
                key: "companyName",
                headerClassName: styles.headerColumn,
                paddingClassName: styles.columnPadding,
            },
            {
                headerLabel: tr(TEXT_ADDRESS),
                width: "23%",
                key: "utilityUnit",
                headerClassName: styles.headerColumn,
                paddingClassName: styles.columnPadding,
            }
        )
    }

    const products = residentialInfo.flatMap(x =>
        (x.products.length > 0 ? x.products : [{} as ContractProduct]).map(p => ({
            ...p,
            utilityUnit: `${x.address} ${x.residentialNumber}`,
            companyName: x.name,
        }))
    )

    return (
        <Paper className={styles.paper}>
            <h3 className={styles.contractsTitle}>
                {props.isProductBasedContract ? tr(TEXT_PRODUCTS) : isNso ? tr(TEXT_ORGANIZATIONS_AND_PRODUCTS) : tr(TEXT_UTILITYUNITS_AND_PRODUCTS)}
            </h3>
            <CubitTable
                flat={true}
                name="contract-products"
                columns={columns}
                data={products}
                sorting={
                    props.isProductBasedContract || isNso
                        ? { direction: "desc", by: "name" }
                        : { direction: "desc", by: "utilityUnit" }
                }
            />
        </Paper>
    )
}

export { ContractProducts }
